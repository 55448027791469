dmx.Component('fetch', {

    constructor: function(node, parent) {
        this.fetch = dmx.debounce(this.fetch.bind(this));
        dmx.BaseComponent.call(this, node, parent);
    },

    initialData: {
        status: 0,
        data: null,
        links: {},
        paging: {},
        headers: {},
        state: {
            executing: false,
            uploading: false,
            processing: false,
            downloading: false
        },
        uploadProgress: {
            position: 0,
            total: 0,
            percent: 0
        },
        downloadProgress: {
            position: 0,
            total: 0,
            percent: 0
        },
        lastError: {
            status: 0,
            message: '',
            response: null
        }
    },

    attributes: {
        timeout: {
            type: Number,
            default: 0 // timeout in seconds
        },

        method: {
            type: String,
            default: 'GET' // HTTP request method to use, such as "GET", "POST", "PUT", "DELETE"
        },

        url: {
            type: String,
            default: ''
        },

        params: {
            type: Object,
            default: {}
        },

        headers: {
            type: Object,
            default: {}
        },

        data: {
            type: Object,
            default: {}
        },

        'data-type': {
            type: String,
            default: 'auto' // auto, json, text
        },

        noload: {
            type: Boolean,
            default: false
        },

        cache: {
            type: String,
            default: ''
        },

        ttl: {
            type: Number,
            default: 86400 // cache ttl in seconds (default 1 day)
        }
    },

    methods: {
        abort: function() {
            this.abort();
        },

        load: function(params, reload) {
            var options = {};
            if (params) options.params = params;
            if (reload) options.ttl = 0;
            this.fetch(options);
        },

        reset: function() {
            this.abort();
            this._reset();
            this.set('data', null);
        }
    },

    events: {
        start: Event, // when starting an ajax call
        done: Event, // when ajax call completed (success and error)
        error: Event, // server error or javascript error (json parse or network transport) or timeout error
        invalid: Event, // 400 status from server
        unauthorized: Event, // 401 status from server
        forbidden: Event, // 403 status from server
        abort: Event, // ajax call was aborted
        success: Event, // successful ajax call,
        upload: ProgressEvent, // on upload progress
        download: ProgressEvent // on download progress
    },

    $parseAttributes: function(node) {
        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);
        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'param' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.params[attr.argument] = value;
                });
            }
            if (attr.name == 'header' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.headers[attr.argument] = value;
                });
            }
            if (attr.name == 'data' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.data[attr.argument] = value;
                });
            }
        }, this);
    },

    render: function(node) {
        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));
        this.xhr.addEventListener('progress', this.onprogress('download').bind(this));
        if (this.xhr.upload) this.xhr.upload.addEventListener('progress', this.onprogress('upload').bind(this));

        this.update({});
    },

    update: function(props) {
        // if auto load and url is set
        if (!this.props.noload && this.props.url) {
            // if url or params are changed
            if (props.url != this.props.url || !dmx.equal(props.params, this.props.params)) {
            //if (props.url !== this.props.url || JSON.stringify(props.params) !== JSON.stringify(this.props.params)) {
                this.fetch();
            }
        }
    },

    abort: function() {
        this.xhr.abort();
    },

    fetch: function(options) {
        this.xhr.abort();

        options = dmx.extend(true, this.props, options || {});

        this._reset();
        this.dispatchEvent('start');

        var qs = (options.url.indexOf('?') > -1 ? '&' : '?') + Object.keys(options.params).filter(function(key) {
            return options.params[key] != null;
        }, this).map(function(key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(options.params[key]);
        }, this).join('&');

        this._url = options.url + qs;

        if (this.props.cache) {
            var cache = dmx.parse(this.props.cache + '.data["' + this._url + '"]', this);
            if (cache) {
                if (Date.now() - cache.created >= options.ttl * 1000) {
                    dmx.parse(this.props.cache + '.remove("' + this._url + '")', this);
                } else {
                    this.set('headers', cache.headers || {});
                    this.set('paging', cache.paging || {});
                    this.set('links', cache.links || {});
                    this.set('data', cache.data);
                    this.dispatchEvent('success');
                    this.dispatchEvent('done');
                    return;
                }
            }
        }

        this.set('state', {
            executing: true,
            uploading: false,
            processing: false,
            downloading: false
        });

        var data = null;

        if (this.props.method.toUpperCase() != 'GET') {
            if (this.props['data-type'] == 'text') {
                if (!options.headers['Content-Type']) {
                    options.headers['Content-Type'] = 'application/text';
                }
                data = this.props.data.toString();
            } else if (this.props['data-type'] == 'json') {
                if (!options.headers['Content-Type']) {
                    options.headers['Content-Type'] = 'application/json';
                }
                data = JSON.stringify(this.props.data);
            } else {
                if (this.props.method.toUpperCase() == 'POST') {
                    data = new FormData();

                    Object.keys(this.props.data).forEach(function(key) {
                        var value = this.props.data[key];

                        if (Array.isArray(value)) {
                            if (!/\[\]$/.test(key)) {
                                key += '[]';
                            }
                            value.forEach(function(val) {
                                data.append(key, val);
                            }, this);
                        } else {
                            data.set(key, value);
                        }
                    }, this);
                } else {
                    if (!options.headers['Content-Type']) {
                        options.headers['Content-Type'] = 'application/text';
                    }
                    data = this.props.data.toString();
                }
            }
        }

        this.xhr.open(this.props.method.toUpperCase(), this._url);
        this.xhr.timeout = options.timeout * 1000;
        Object.keys(options.headers).forEach(function(header) {
            this.xhr.setRequestHeader(header, options.headers[header]);
        }, this);
        this.xhr.setRequestHeader('accept', 'application/json');
        try { this.xhr.send(data); }
        catch (err) { this._done(err); }
    },

    _reset: function() {
        this.set({
            status: 0,
            links: {},
            headers: {},
            state: {
                executing: false,
                uploading: false,
                processing: false,
                downloading: false
            },
            uploadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            downloadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            lastError: {
                status: 0,
                message: '',
                response: null
            }
        });
    },

    _done: function(err) {
        this._reset();

        if (err) {
            this.set('lastError', {
                status: 0,
                message: err.message,
                response: null
            });

            this.dispatchEvent('error');
        } else {
            var response = this.xhr.responseText;

            try {
                response = JSON.parse(response);
            } catch(err) {
                if (this.xhr.status < 400) {
                    this.set('lastError', {
                        status: 0,
                        message: 'Response was not valid JSON',
                        response: response
                    });

                    this.dispatchEvent('error');
                    return;
                }
            }

            try {
                // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders#Example
                var strHeaders = this.xhr.getAllResponseHeaders();
                var arrHeaders = strHeaders.trim().split(/[\r\n]+/);

                this.set('headers', arrHeaders.reduce(function(headers, line) {
                    var parts = line.split(': ');
                    var header = parts.shift();
                    var value = parts.join(': ');

                    headers[header] = value;

                    return headers;
                }, {}));
            } catch(err) {
                console.warn('Error parsing response headers', err);
            }

            try {
                //var linkHeader = this.xhr.getResponseHeader('Link');
                var linkHeader = Object.keys(this.data.headers).find(function(header) {
                    return header.toLowerCase() == 'link';
                });

                if (linkHeader) {
                    this.set('links', this.data.headers[linkHeader].split(/,\s*</).map(function(link) {
                        try {
                            var m = link.match(/<?([^>]*)>(.*)/);
                            var linkUrl = m[1];
                            var parts = m[2].split(';');
                            var query = linkUrl.substr(linkUrl.indexOf('?') + 1);
                            if (query.indexOf('#') > 0) query = query.substr(0, query.indexOf('#'));
                            var qry = query.split('&').reduce(function(acc, x) {
                                var p = x.split('=');

                                if (p[0]) {
                                    acc[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
                                }

                                return acc;
                            }, {});

                            parts.shift();

                            var info = parts.reduce(function(acc, p) {
                                var m = p.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);
                                if (m) acc[m[1]] = m[2];
                                return acc;
                            }, {});

                            info = Object.assign({}, qry, info);
                            info.url = linkUrl;

                            return info;
                        } catch (err) {
                            console.warn('Error parsing link header part', err);
                            return null;
                        }
                    }).filter(function(x) {
                        return x && x.rel;
                    }).reduce(function(acc, x) {
                        x.rel.split(/\s+/).forEach(function(rel) {
                            acc[rel] = Object.assign(x, { rel: rel });
                        });

                        return acc;
                    }, {}));
                }
            } catch (err) {
                console.warn('Error parsing link header', err);
            }

            try {
                var paging = {
                    page: 1,
                    pages: 1,
                    items: 0,
                    has: {
                        first: false,
                        prev: false,
                        next: false,
                        last: false
                    }
                };

                if (this.data.links.prev || this.data.links.next) {
                    if (this.data.links.last && this.data.links.last.page) {
                        paging.pages = +this.data.links.last.page;
                    } else if (this.data.links.prev && this.data.prev.page) {
                        paging.pages = +this.data.links.prev.page + 1;
                    }

                    var countHeader = Object.keys(this.data.headers).find(function(header) {
                        header = header.toLowerCase();
                        return header == 'x-total' || header == 'x-count' || header == 'x-total-count';
                    });

                    if (countHeader) {
                        paging.items = +this.data.headers[countHeader];
                    }

                    if (this.data.links.prev && this.data.links.prev.page) {
                        paging.page = +this.data.links.prev.page + 1;
                    } else if (this.data.links.next && this.data.links.next.page) {
                        paging.page = +this.data.links.next.page - 1;
                    }

                    paging.has = {
                        first: !!this.data.links.first,
                        prev: !!this.data.links.prev,
                        next: !!this.data.links.next,
                        last: !!this.data.links.last
                    }
                }

                this.set('paging', paging);
            } catch (err) {
                console.warn('Error parsing paging', err);
            }

            this.set('status', this.xhr.status);

            if (this.xhr.status < 400) {
                this.set('data', response);
                this.dispatchEvent('success');

                if (this.props.cache) {
                    dmx.parse(this.props.cache + '.set("' + this._url + '", { headers: headers, paging: paging, links: links, data: data, created: ' + Date.now() + ' })', this);
                }
            } else {
                this.set('lastError', {
                    status: this.xhr.status,
                    message: this.xhr.statusText,
                    response: response
                });

                if (this.xhr.status == 400) {
                    this.dispatchEvent('invalid');
                } else if (this.xhr.status == 401) {
                    this.dispatchEvent('unauthorized');
                } else if (this.xhr.status == 403) {
                    this.dispatchEvent('forbidden');
                } else {
                    this.dispatchEvent('error');
                }
            }
        }

        this.dispatchEvent('done');
    },

    onload: function(event) {
        this._done();
    },

    onabort: function(event) {
        this._reset();
        this.dispatchEvent('abort');
        this.dispatchEvent('done');
    },

    onerror: function(event) {
        this._done({ message: 'Failed to execute' });
    },

    ontimeout: function(event) {
        this._done({ message: 'Execution timeout' });
    },

    onprogress: function(type) {
        return function(event) {
            event.loaded = event.loaded || event.position;

            var percent = event.lengthComputable ? Math.ceil(event.loaded / event.total * 100) : 0;

            this.set('state', {
                executing: true,
                uploading: type == 'upload' && percent < 100,
                processing: type == 'upload' && percent == 100,
                downloading: type == 'download'
            });

            this.set(type + 'Progress', {
                position: event.loaded,
                total: event.total,
                percent: percent
            });

            this.dispatchEvent(type, {
                lengthComputable: event.lengthComputable,
                loaded: event.loaded,
                total: event.total
            });
        };
    }

});
